<template>
  <div class="hamburger-menu-container">
    <div class="banner-container">
      <HeaderBanner />
    </div>
    <HeaderMobileMenuItem :href="'/podobrat-vracha'" text="Подобрать врача" :is-recruiter="true">
      <IconStars filled class="icon" />
    </HeaderMobileMenuItem>
    <HeaderMobileMenuDoctors />
    <HeaderMobileMenuClinics />
    <HeaderMobileMenuItem :href="'/uslugi'" text="Услуги">
      <IconStethoscope class="icon-stethoscope" />
    </HeaderMobileMenuItem>
    <HeaderMobileMenuItem :href="'/akczii'" text="Акции">
      <IconMenuPromotions class="icon" />
    </HeaderMobileMenuItem>
    <HeaderMobileMenuItem :href="'/kontakty'" text="Контакты">
      <IconMenuContacts class="icon" />
    </HeaderMobileMenuItem>
    <HeaderMobileMenuItem :href="'/otzyvy'" text="Отзывы">
      <IconReview filled class="icon" />
    </HeaderMobileMenuItem>
    <HeaderMobileMenuItem :href="'/vakansii'" text="Работа в Ист Клинике">
      <IconWork class="icon" />
    </HeaderMobileMenuItem>
  </div>
</template>

<style scoped lang="scss">
.hamburger-menu-container {
  @include scroll;
  overflow-y: auto;
  overflow-x: auto;
  z-index: 902;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: -52px;
  width: 100%;
  height: 100%;
  padding: 0px 8px 90px 8px;
  background-color: white;
}
.banner-container {
  padding: 20px 12px;
}
.icon-user {
  font-size: 1.5rem;
  margin: 0;
}
.icon {
  font-size: 1.5rem;
  color: transparent;
  margin: 0;
}
.icon-stethoscope {
  font-size: 1.5rem;
  margin: 0;
}
</style>
