<script setup lang="ts">
  const lists = [
    {
      title: 'Навигация',
      links: [
        { url: '/', text: 'Главная' },
        { url: '/vrachi', text: 'Врачи' },
        { url: '/kontakty', text: 'Клиники' },
        { url: '/uslugi', text: 'Услуги' },
        { url: '/akczii', text: 'Акции' },
        { url: '/kontakty', text: 'Контакты' },
        { url: '/', text: 'Карта сайта' }
      ]
    },
    {
      title: 'Специальности',
      links: [
        { url: '/vrachi/nevrolog', text: 'Неврологи' },
        { url: '/vrachi/osteopat', text: 'Остеопаты' },
        { url: '/vrachi/manualnyj-terapevt', text: 'Мануальные терапевты' },
        { url: '/vrachi/travmatolog-ortoped', text: 'Травматологи-ортопеды' },
        { url: '/vrachi/ginekolog', text: 'Гинекологи' },
        { url: '/vrachi/urolog', text: 'Урологи' },
        { url: '/vrachi/psihoterapevt', text: 'Психотерапевты' },
        { url: '/vrachi/vrach-uzi', text: 'УЗИ' }
      ]
    },
    {
      title: 'Ист Клиника',
      links: [
        { url: '/kontakty/licenziya', text: 'Документы и лицензии' },
        { url: '/my-v-smi', text: 'Мы в СМИ' },
        { url: '/vakansii', text: 'Вакансии' },
        { url: '/otzyvy', text: 'Отзывы' }
      ]
    }
  ];
</script>

<template>
  <div class="navigation-container">
    <FooterNavigationList
      v-for="list in lists"
      :key="list.title"
      v-bind="list"
    />
  </div>
</template>

<style scoped lang="scss">
.navigation-container {
  display: flex;
  justify-content: space-between;
  width: 85%;
  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
    justify-content: none;
    align-items: center;
  }
}
</style>
