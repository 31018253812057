<template>
  <div class="oversight-container">
    <div class="voting-container">
      <img src="~/assets/img/footer/emblem-russia.webp" alt="emblem-russia" class="emblem-russia">
      <div class="voting-text-container">
        <span class="main-text">Независимая оценка качества оказания услуг медицинским организациям</span>
        <NuxtLink to="https://anketa.rosminzdrav.ru/staticogvjustank/30/1" external class="voting-link">
          Участвовать в голосовании
        </NuxtLink>
      </div>
    </div>
    <div class="emblems-container">
      <NuxtLink to="https://roszdravnadzor.gov.ru" external class="emblem">
        <img src="~/assets/img/footer/emblem-1.png" alt="emblem-1" class="emblem-img">
        <span class="emblem-text">Федеральная служба по надзору в сфере здравоохранения</span>
      </NuxtLink>
      <NuxtLink to="https://77.rospotrebnadzor.ru" external class="emblem">
        <img src="~/assets/img/footer/emblem-2.png" alt="emblem-2" class="emblem-img">
        <span class="emblem-text">Управление Роспотребнадзора по городу Москве</span>
      </NuxtLink>
    </div>
    <div class="payment-container">
      <LazyOperatorAuth v-if="useContext() === 'operator'" />
      <div class="payment-text">
        Мы принимаем к оплате
      </div>
      <div class="payment-list">
        <IconMir filled :font-controlled="false" class="payment-type" />
        <IconVisa filled :font-controlled="false" class="payment-type" />
        <IconMastercard filled :font-controlled="false" class="payment-type" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.oversight-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.voting-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.emblem-russia {
  width: 68px;
  height: 74px;
}
.voting-text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.main-text {
  color: #878FA2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  width: 184px;
}
.voting-link {
  color: #2D7FF9 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.014px;
  text-decoration: none;
}
.emblems-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 1023px) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.emblem {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid #E4E7EF;
  text-decoration: none;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.emblem-img {
  width: 32px;
  height: 32px;
}
.emblem-text {
  width: 199px;
  color: #878FA2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.payment-text {
  margin-bottom: 12px;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.payment-list {
  display: flex;
  gap: 8px;
}
.payment-type {
  width: 68px;
  height: 39px;
  margin: 0;
}
</style>
